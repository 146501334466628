import type { Component } from 'solid-js'

const App: Component = () => {
	return (
		<div>
			<header>
				<h1>Doveki</h1>
			</header>
		</div>
	)
}

export default App
